.faq {
  &__wrapper {
    max-width: 720px;
    padding-top: $baseUnit * 10;
    padding-bottom: $baseUnit * 10;

    @media only screen and (min-width: $screen-md) {
      padding-top: $baseUnit * 21;
      padding-bottom: $baseUnit * 21;
    }
  }

  &__heading {
    font-size: 26px;
    color: $primary;
    margin-bottom: $baseUnit * 9;

    @media only screen and (min-width: $screen-md) {
      font-size: 49px;
    }
  }

  &__item {
    cursor: pointer;
    margin-bottom: $baseUnit * 8;

    &__question {
      margin-bottom: $baseUnit * 4;
      font-size: 18px;

      @media only screen and (min-width: $screen-md) {
        font-size: 27px;
      }
    }

    &__answer {
      display: none;
      p {
        font-size: 14px;
        line-height: 20px;

        @media only screen and (min-width: $screen-md) {
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }
}
