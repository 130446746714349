.partners {
  padding: $baseUnit * 7 0;

  @media only screen and (min-width: $screen-md) {
    padding: $baseUnit * 19 0;
  }

  &__heading {
    text-align: center;
    display: block;
    margin-bottom: $baseUnit * 3;

    @media only screen and (min-width: $screen-md) {
      margin-bottom: $baseUnit * 7;
    }
  }

  &__logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &__logo {
      flex: 1;
      margin: 0 $baseUnit * 2;
      min-width: 120px;
      max-height: 54px;
      margin-bottom: $baseUnit * 2;
    }
  }
}
