.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: $white;
  padding: $baseUnit * 7 $baseUnit * 3;

  @media only screen and (min-width: $screen-md) {
    padding: $baseUnit * 9;
    justify-content: flex-end;
    height: 650px;
    padding: 0;

    &__bg {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      z-index: 5;

      background: linear-gradient(
        270deg,
        $white 18.26%,
        rgba(255, 255, 255, 0) 104.99%
      );
    }
  }

  &__carousel {
    display: none !important;
    &__image {
      background-image: url("../assets/hero.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      height: 650px;
      width: 100%;

      &-1 {
        background-image: url("../assets/hero-2.jpg");
      }

      &-2 {
        background-image: url("../assets/hero-3.jpg");
      }
    }

    @media only screen and (min-width: $screen-md) {
      display: block !important;
      position: absolute !important;
      top: 0;
      right: 0;
      bottom: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__content {
    z-index: 10;
    position: relative;
    width: 300px;
    float: right;

    @media only screen and (min-width: $screen-md) {
      width: 620px;
    }

    &__title {
      color: $primary;
      font-weight: 800;
      margin: 0;
      font-size: 26px;
      line-height: 1.2;
      padding: 0;
      text-transform: uppercase;
      margin-bottom: $baseUnit * 2;

      @media only screen and (min-width: $screen-md) {
        margin-bottom: $baseUnit * 6;
        font-size: 49px;
        width: 570px;
      }
    }

    &__claim {
      background-color: $white;

      &__text {
        display: block;
        font-size: 14px;
        line-height: 1.5;

        @media only screen and (min-width: $screen-md) {
          font-size: 21px;
          line-height: 1.7;
          padding: $baseUnit * 7;
          max-width: 410px + ($baseUnit * 7 * 2);
        }
      }
    }
  }
}
