.detail {
  .wrapper {
    padding-top: $baseUnit * 12;
    padding-bottom: $baseUnit * 12;
  }

  &__heading {
    font-weight: normal;
    color: $primary;
    font-size: 35px;
    margin-bottom: $baseUnit * 8;
  }

  &__image {
    width: 100%;
    height: auto;
    margin-bottom: $baseUnit * 4;
  }

  h2 {
    margin-top: $baseUnit * 8;
    margin-bottom: $baseUnit * 8;
    color: $black;
  }
}
