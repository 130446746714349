.news {
  .wrapper {
    padding-top: $baseUnit * 12;
    padding-bottom: $baseUnit * 12;
  }

  &__heading {
    font-weight: normal;
    color: $primary;
    font-size: 49px;
    margin-bottom: $baseUnit * 8;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
      margin-bottom: $baseUnit * 9;

      @media only screen and (min-width: $screen-md) {
        width: calc(50% - 15px);
      }

      &__image {
        width: 100%;
        height: auto;
        margin-bottom: $baseUnit * 3;
      }

      &__date {
        font-size: 14;
      }

      &__perex {
        font-weight: bold;
      }
    }
  }
}
