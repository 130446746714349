.paragon {
  padding: $baseUnit * 10 0;

  @media only screen and (min-width: $screen-md) {
    padding: $baseUnit * 19 0;
  }

  &__content {
    max-width: 800px;
    margin: 0 auto $baseUnit * 12;
    padding: 0 $baseUnit * 8;

    li {
      a {
        padding: 0 4px;
      }
    }

    &__heading {
      font-weight: bold;
      font-size: 26px;
      line-height: 1.2;
      margin-bottom: $baseUnit * 5;

      @media only screen and (min-width: $screen-md) {
        margin-bottom: $baseUnit * 10;
        font-size: 42px;
        align-items: center;
        display: flex;
      }

      img {
        width: 74px;
        height: auto;
        margin-right: $baseUnit * 7;
        margin-bottom: $baseUnit * 3;

        @media only screen and (min-width: $screen-md) {
          width: 128px;
          margin-bottom: $baseUnit * 5;
          margin-bottom: 0;
        }
      }

      &__text {
        display: block;
        @media only screen and (min-width: $screen-md) {
          flex: 1;
        }
      }
    }

    &__subheading {
      font-weight: bold;
      line-height: 32px;
      color: $primary;
      margin-bottom: $baseUnit * 9;
      font-size: 21px;

      @media only screen and (min-width: $screen-md) {
        font-size: 27px;
      }
    }

    &__comment {
      font-size: 14px;
      line-height: 20px;
      max-width: 710px;
      margin: 0 auto $baseUnit * 7;

      @media only screen and (min-width: $screen-md) {
        text-align: center;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }

  &__how {
    background-color: $bg;
    margin-bottom: $baseUnit * 10;
    padding: $baseUnit * 7 0 $baseUnit * 4;

    @media only screen and (min-width: $screen-md) {
      padding: $baseUnit * 10 0;
      text-align: center;
    }

    &__content {
      margin: 0 auto $baseUnit * 12;
      padding: 0 $baseUnit * 11;

      @media only screen and (min-width: $screen-md) {
        padding: 0 $baseUnit * 8;
      }
    }

    &__carousel {
      position: relative;
      padding-top: $baseUnit * 16;
      @media only screen and (min-width: $screen-md) {
        padding-top: 0;
      }

      .owl-dots {
        counter-reset: dots;
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .owl-dot {
        background-color: $darkGray !important;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        height: 52px;
        min-width: 52px;
        height: 52px;
        display: flex;
        font-size: 18px;
        width: 52px;
        min-width: 52px;
        height: 52px;
        margin-bottom: $baseUnit * 3;
        margin-right: $baseUnit * 3;
        margin-left: $baseUnit * 3;
        transition: all 0.3s;

        &.active,
        &:hover {
          background-color: $primary !important;
        }
      }

      .owl-dot:before {
        font-size: 18px;
        color: $white;
        counter-increment: dots;
        content: counter(dots);
      }

      @media only screen and (min-width: $screen-md) {
        display: flex;
      }

      &__item {
        @media only screen and (min-width: $screen-md) {
          flex: 1;
          display: flex;
          flex-flow: column;
          align-items: center;
          padding: 0 $baseUnit * 3;
        }

        @media only screen and (min-width: $screen-lg) {
          padding: 0 $baseUnit * 8;
        }

        &__number {
          display: none;

          @media only screen and (min-width: $screen-md) {
            color: $white;
            background-color: $primary;
            font-weight: bold;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            font-size: 18px;
            height: 52px;
            min-width: 52px;
            height: 52px;
            display: flex;
            font-size: 18px;
            width: 52px;
            min-width: 52px;
            height: 52px;
            margin-bottom: $baseUnit * 3;
          }
        }

        &__heading {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: $baseUnit * 9;
          text-align: left;
          min-height: 40px;

          @media only screen and (min-width: $screen-md) {
            text-align: center;
          }

          @media only screen and (min-width: $screen-xl) {
            min-height: 0;
          }
        }

        &__image {
          height: auto;
          max-height: 280px;
          max-width: 100%;
          width: auto !important;
          margin: 0 auto;

          @media only screen and (min-width: $screen-md) {
            margin: 0 auto;
          }

          &__wrapper {
            max-height: none;
            width: 100% !important;
            margin-bottom: $baseUnit * 5;
            height: 280px;

            @media only screen and (min-width: $screen-md) {
              height: auto;
              min-height: 283px;
            }

            @media only screen and (min-width: $screen-lg) {
              min-height: 303px;
            }
          }
        }

        &__description {
          text-align: left;
          font-size: 14px;
          line-height: 20px;

          @media only screen and (min-width: $screen-md) {
            text-align: center;
            font-size: 18px;
            line-height: 27px;
          }
        }
      }
      .owl-next {
        margin-right: -55px;
        margin-top: -70px;
      }
    }
  }

  &__card {
    max-width: 720px;
    margin: 0 auto $baseUnit * 8;

    &__content {
      padding: $baseUnit * 11;
      background-color: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;

      &__description {
        text-align: center;
        margin-bottom: 20px;

        &__price {
          padding-top: $baseUnit * 2;
          height: 81px;
          width: 81px;
          min-width: 81px;
          border-radius: 81px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $white;
          background-color: $darkGray;
          font-size: 20px;
          line-height: 0.9;
          flex-flow: column;
          margin: 0 auto $baseUnit * 3;

          &__vat {
            font-size: 10px;
            line-height: 1.8;
          }
        }

        &__info {
          min-height: 31px;
          color: $white;
          font-weight: bold;
          font-size: 10px;
          line-height: 14px;

          @media only screen and (min-width: $screen-md) {
            margin-left: $baseUnit * 2;
            min-height: 54px;
            line-height: 27px;
            font-size: 16px;
          }
        }
      }
      &__button {
        display: inline-block;
        background-color: $white;
        color: $primary;
        font-weight: bold;
        line-height: 1.5;
        text-transform: uppercase;
        border: 1px solid transparent;
        text-decoration: none;
        font-size: 12px;
        padding: $baseUnit $baseUnit * 4;
        margin-bottom: $baseUnit * 9;
        cursor: pointer;

        @media only screen and (min-width: $screen-md) {
          margin-bottom: $baseUnit * 11;
          font-size: 19px;
          padding: $baseUnit * 2 $baseUnit * 7;
        }

        &:hover,
        &:focus {
          border: 1px solid $white;
          background-color: $primary;
          color: $white;
        }
      }
    }

    &__footer {
      width: 100%;
      background-color: $gray;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 $baseUnit * 6.5;
      text-align: center;
      flex-flow: column;

      @media only screen and (min-width: $screen-md) {
        background-color: $bg;
        position: relative;
        z-index: 5;
        top: $baseUnit * -11;
      }

      &__link {
        font-size: 12px;
        height: 83px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }

        @media only screen and (min-width: $screen-md) {
          font-size: 16px;
        }

        @media only screen and (min-width: $screen-lg) {
          font-size: 18px;
        }
      }

      &__content {
        display: none;
        p {
          margin-bottom: $baseUnit * 7;
          text-align: left;
        }

        &__heading {
          font-size: 18px;
          line-height: 21px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: $baseUnit * 8;

          @media only screen and (min-width: $screen-md) {
            margin-bottom: $baseUnit * 6;
            font-size: 27.3799px;
            line-height: 32px;
          }
        }

        &__button {
          background-color: $primary;
          color: $white;
          font-weight: bold;
          line-height: 1.5;
          text-transform: uppercase;
          border: 1px solid transparent;
          text-decoration: none;
          font-size: 12px;
          padding: $baseUnit $baseUnit * 4;
          transition: all 0.3s;
          cursor: pointer;
          display: table;
          margin: $baseUnit * 6 auto $baseUnit * 8;

          @media only screen and (min-width: $screen-md) {
            font-size: 19px;
            padding: $baseUnit * 2 $baseUnit * 7;
            margin: $baseUnit * 12 auto $baseUnit * 16;
          }

          &:hover,
          &:focus {
            border: 1px solid $primary;
            background-color: $white;
            color: $primary;
          }
        }
      }
    }

    &__list {
      li {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: left;

        @media only screen and (min-width: $screen-md) {
          font-size: 18px;
          line-height: 27px;
        }

        &::before {
          min-width: 34.1px;
          width: 34.1px;
          height: 34.1px;
          font-size: 11.6059px;
          line-height: 13px;
        }
      }
    }
  }

  &__price-list {
    max-width: 800px;
    margin: 0 auto $baseUnit * 12;
    padding: 0 $baseUnit * 8;

    &__row {
      display: flex;
      margin: 0 $baseUnit * -8;

      @media only screen and (min-width: $screen-md) {
        margin: 0;
      }
    }

    &__text {
      border: 1px solid $white;
      background-color: $gray;
      font-size: 14px;
      line-height: 20px;
      padding: $baseUnit * 2 $baseUnit * 8;
      flex: 1;

      @media only screen and (min-width: $screen-md) {
        font-size: 18px;
        line-height: 27px;
      }
    }

    &__price {
      border: 1px solid $white;
      background-color: $gray;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      padding: $baseUnit * 2 $baseUnit $baseUnit * 2 $baseUnit * 4;
      width: 112px;
      display: flex;
      align-items: center;

      @media only screen and (min-width: $screen-md) {
        font-size: 18px;
        line-height: 27px;
      }

      &--free {
        color: $primary;
      }
    }
  }

  &__note {
    max-width: 800px;
    margin: 0 auto $baseUnit * 14;
    padding: 0 $baseUnit * 8;

    @media only screen and (min-width: $screen-md) {
      font-size: 18px;
      line-height: 27px;
    }

    i {
      display: block;
      margin: 0 auto $baseUnit * 7;
    }
  }

  &__terminal {
    max-width: 800px;
    margin: 0 auto $baseUnit * 7;
    padding: 0 $baseUnit * 8;

    &__button {
      display: table;
      margin: $baseUnit * 8 auto;
      background-color: $primary;
      color: $white;
      font-weight: bold;
      line-height: 1.5;
      text-transform: uppercase;
      border: 1px solid transparent;
      text-decoration: none;
      font-size: 12px;
      padding: $baseUnit $baseUnit * 4;
      cursor: pointer;

      @media only screen and (min-width: $screen-md) {
        font-size: 19px;
        padding: $baseUnit * 2 $baseUnit * 7;
      }

      &:hover,
      &:focus {
        border: 1px solid $primary;
        background-color: $white;
        color: $primary;
      }
    }
    p {
      @media only screen and (min-width: $screen-md) {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}
