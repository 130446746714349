.sign-in {
  .wrapper {
    margin-top: $baseUnit * 20;
    margin-bottom: $baseUnit * 20;
  }

  &__heading {
    font-size: 49.0119px;
    line-height: 57px;
    font-weight: bold;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: $baseUnit * 8;
  }
}
