.header {
  color: $white;
  position: fixed;
  width: 100%;
  z-index: 100;
  background: $primary;
  border-bottom: 1px solid $white;

  .wrapper {
    @media (min-width: $screen-md) and (max-width: $screen-lg) {
      padding-right: 0;
      padding-left: $baseUnit * 2;
    }
  }

  &__content {
    background: $primary;
    padding-top: $baseUnit * 4;
    padding-bottom: $baseUnit * 4;
    display: flex;
    flex-flow: row;
    align-items: center;

    @media only screen and (min-width: $screen-md) {
      padding-bottom: $baseUnit * 6;
      padding-top: $baseUnit * 6;
    }
  }

  &__logo {
    color: $white;

    img {
      height: 49px;
      width: auto;

      @media only screen and (min-width: $screen-md) {
        height: 65px;
      }
    }
  }

  &__phone {
    margin-left: auto;

    &__link {
      text-decoration: none;
      display: flex;
      align-items: center;

      &:hover,
      &focus {
        text-decoration: underline;
        color: $darkGray;
      }
    }

    img {
      height: 26px;
      width: auto;
      @media only screen and (min-width: $screen-md) {
        height: 25px;
      }
    }
  }

  &__navigation {
    margin-left: auto;

    &__list {
      $p: &;
      list-style-type: none;
      margin: 0 0 0 $baseUnit * 10;
      padding: 0;
      display: none;
      margin-bottom: -27px;

      @media only screen and (min-width: $screen-md) {
        display: flex;
        align-items: center;
      }

      &--upper {
        justify-content: flex-end;
        margin-bottom: 26px;
        margin-top: -25px;

        img {
          height: 20px;
          width: auto;
          float: left;
          padding-right: 8px;
        }

        #{$p}__item {
          font-size: 14px;
          line-height: 19px;
        }
      }

      &__item {
        font-size: 18px;
        padding: 0 $baseUnit * 3;
        @media (min-width: $screen-md) and (max-width: $screen-lg) {
          font-size: 15px;
        }

        &:last-of-type {
          padding-right: 0;
        }

        &__link {
          color: $white;
          text-transform: uppercase;
          text-decoration: none;

          &:hover,
          &:focus {
            color: $white;
            opacity: 0.8;
          }
        }
      }
    }

    &__mobile-toggle {
      $p: &;
      padding: $baseUnit * 2 0 $baseUnit * 2 $baseUnit * 4;
      position: relative;
      transition: 0.5s ease-in-out;
      cursor: pointer;

      @media only screen and (min-width: $screen-md) {
        display: none;
      }

      &__line {
        width: 26px;
        height: 2px;
        background-color: $white;
        display: block;
        transition: all 0.3s ease-in-out;

        &:first-of-type {
          margin-bottom: 8px;
        }
      }

      &.open {
        #{$p}__line {
          &:first-of-type {
            transform: translateY(5px) rotate(45deg);
          }

          &:last-of-type {
            transform: translateY(-5px) rotate(-45deg);
          }
        }
      }
    }
  }

  &__search {
    background: $bg;
    height: 125px;
    display: flex;
    margin-top: -$baseUnit * 6;
    margin-bottom: -$baseUnit * 6;
    padding-top: $baseUnit * 6;
    padding-bottom: $baseUnit * 6;
    padding-right: $baseUnit * 5;
    padding-left: $baseUnit * 5;
    margin-left: $baseUnit * 6;
    flex-flow: column;
    display: none;
    position: relative;

    @media only screen and (min-width: $screen-md) {
      display: block;
    }

    p {
      font-size: 12px;
      line-height: 16px;
      color: $black;
    }

    form {
      position: relative;
    }

    &__input {
      background-color: $white !important;
    }

    #search-submit {
      position: absolute;
      top: 0;
      right: 0;
      width: 36px !important;
      height: 36px !important;
      background-color: $primary !important;
      background-image: url("../assets/search.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }

    &__preview {
      display: none;
      position: absolute;
      width: 755px;
      max-width: 100vw;
      right: -25px;
      top: calc(100% + 29px);
    }
  }

  &__mobile-menu {
    height: calc(100vh - 78px);
    position: fixed;
    top: 78px;
    width: 100%;
    background: $darkGray;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transform: translateY(-120%);
    z-index: -1;
    padding: $baseUnit * 6;
    display: block;
    justify-content: center;
    overflow: scroll;

    @media only screen and (min-width: $screen-md) {
      display: none !important;
    }

    &.open {
      display: block;
      transform: translateY(0);
    }

    &__link {
      font-size: 18px;
      color: $white;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: $baseUnit * 4;
      text-align: center;
    }

    &__my-account {
      color: $primary;
      img {
        margin-right: $baseUnit * 2;
      }
    }

    &__divider {
      padding: $baseUnit * 9 0 $baseUnit * 5;
      position: relative;

      &::after {
        content: "";
        top: 50%;
        width: 110px;
        height: 1px;
        background-color: $border;
        position: absolute;
        left: -50%;
        right: -50%;
        margin: auto;
      }
    }
    &__divider-with-text {
      padding: $baseUnit * 9 0 $baseUnit * 5;
      position: relative;

      &__text {
        color: $border;
        position: absolute;
        font-size: 18px;
        line-height: 21px;
        font-weight: bold;
        top: 33%;
        left: -50%;
        right: -50%;
        margin: auto;
        background: $darkGray;
        text-align: center;
        width: 100px;
        z-index: 10;
        text-transform: uppercase;
      }

      &::after {
        content: "";
        top: 50%;
        width: 100%;
        height: 1px;
        background-color: $border;
        position: absolute;
        left: -50%;
        right: -50%;
        margin: auto;
      }
    }
  }
  &__mobile-search {
    position: fixed;
    background-color: $bg;
    padding: $baseUnit * 4;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (min-width: $screen-md) {
      display: none;
    }

    a {
      cursor: pointer;
    }

    form {
      float: right;
      margin: 0;
      display: none;
    }

    &.open {
      form {
        display: block;
      }
    }

    .form-group {
      position: relative;
      margin: 0;
    }

    #search-submit {
      position: absolute;
      top: 0;
      right: 0;
      width: 36px !important;
      height: 36px !important;
      background-color: $primary !important;
      background-image: url("../assets/search.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
  }
}
