.why {
  padding: $baseUnit * 7 0;

  @media only screen and (min-width: $screen-md) {
    padding: $baseUnit * 19 0;
  }

  &__heading {
    text-align: left;
    display: block;
    margin-bottom: $baseUnit * 6;

    @media only screen and (min-width: $screen-md) {
      text-align: center;
      margin-bottom: $baseUnit * 18;
    }
  }

  &__content {
    @media only screen and (min-width: $screen-md) {
      display: flex;
    }

    &__half {
      $h: &;
      @media only screen and (min-width: $screen-md) {
        flex: 1;
      }

      &:first-of-type {
        @media only screen and (min-width: $screen-md) {
          border-right: 1px solid $gray;
        }
      }

      &__item {
        margin: 0 $baseUnit * 3;
        display: flex;
        border-bottom: 1px solid $gray;
        align-items: center;
        padding-top: $baseUnit * 3;
        padding-bottom: $baseUnit * 3;

        @media only screen and (min-width: $screen-md) {
          padding-top: 0;
          padding-bottom: 0;
          min-height: 0;
          padding-right: $baseUnit * 4;
          padding-left: $baseUnit * 4;

          &:last-child {
            border-bottom: none;
          }
        }

        @media only screen and (min-width: $screen-xl) {
          padding-left: $baseUnit * 10;
        }

        &:first-child {
          @media only screen and (min-width: $screen-md) {
            padding-bottom: $baseUnit * 5;
          }

          @media only screen and (min-width: $screen-lg) {
            padding-bottom: $baseUnit * 10;
          }
        }

        #{$h}:last-of-type &:last-of-type {
          border-bottom: none;
        }

        &:last-child {
          @media only screen and (min-width: $screen-md) {
            padding-top: $baseUnit * 5;
          }

          @media only screen and (min-width: $screen-lg) {
            padding-top: $baseUnit * 10;
          }
        }

        &__left {
          &__image {
            width: 52px;
            height: auto;

            @media only screen and (min-width: $screen-md) {
              width: 78px;
            }

            @media only screen and (min-width: $screen-lg) {
              width: 98px;
            }
          }
        }

        &__right {
          margin-left: $baseUnit * 6;

          @media only screen and (min-width: $screen-lg) {
            margin-left: $baseUnit * 8;
          }

          &__heading {
            margin-bottom: $baseUnit * 1;
            font-size: 14px;
            line-height: 1.4;
            max-width: 301px;

            @media only screen and (min-width: $screen-md) {
              margin-bottom: $baseUnit * 2;
              font-size: 16px;
            }

            @media only screen and (min-width: $screen-lg) {
              font-size: 25px;
            }
          }

          &__description {
            line-height: 1.5;
            max-width: 320px;
            margin-bottom: 0;

            @media only screen and (min-width: $screen-md) {
              font-size: 12px;
            }

            @media only screen and (min-width: $screen-lg) {
              font-size: 18px;
              min-height: 81px;
            }

            @media only screen and (min-width: $screen-xl) {
              min-height: 0;
            }
          }
        }
      }
    }
  }
}
