.search-result {
  background: $bg;
  left: 0;
  top: 105px;
  padding: $baseUnit * 40 0;

  @media only screen and (min-width: $screen-md) {
    top: 126px;
  }

  #sfik-error {
    background: $white;
    width: 100%;
    top: 120px;
    height: 100%;
    z-index: 10;
    position: absolute;
    text-align: center;
    display: none;

    &.show {
      display: block;
    }

    img {
      margin: $baseUnit * 6 0;
    }

    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      max-width: 480px;
      margin: 0 auto;
    }

    #fill_sfik_error {
      color: $primary;
    }
  }

  &__box {
    overflow: hidden;
    background-image: url("../assets/ripped-border.svg");
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    max-width: 704px;

    &::after {
      content: "";
      background: $white;
      height: 50%;
      width: 100%;
      bottom: 0;
      position: absolute;
      z-index: 0;
    }

    &__header {
      position: relative;
      z-index: 1;
      text-align: center;
      padding: $baseUnit * 14 $baseUnit * 2;

      h4 {
        color: $primary;
        font-size: 27.3799px;
        line-height: 32px;
        font-weight: normal;

        &::after {
          content: "*****";
          display: block;
          color: $black;
          margin-top: $baseUnit * 4;
        }
      }

      &__heading {
        color: $black;
        font-weight: normal;
        font-size: 38.01px;
        line-height: 1.2;
      }

      p {
        color: $black !important;
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        margin-bottom: $baseUnit * 4;
      }
    }

    &__content {
      position: relative;
      z-index: 1;
      padding: $baseUnit * 3 $baseUnit * 2;

      table {
        max-width: 480px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: $baseUnit * 10;
        position: relative;
        margin-bottom: $baseUnit * 3;

        &::after {
          content: "–––––––––––––––––––––––––––––––––––";
          position: absolute;
          font-weight: 300;
          font-size: 27.3799px;
          line-height: 32px;
          bottom: 0;
          width: 100%;
          overflow: hidden;
        }
      }

      td {
        display: block;
        font-weight: 600;

        @media only screen and (min-width: $screen-md) {
          display: table-cell;
        }
      }

      td:first-child {
        min-width: 170px;
        font-weight: normal;
      }

      &__price {
        color: $black;
        max-width: 480px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: $baseUnit * 14;
        position: relative;
        margin-bottom: $baseUnit * 5;

        &::after {
          content: "–––––––––––––––––––––––––––––––––––";
          position: absolute;
          font-weight: 300;
          font-size: 27.3799px;
          line-height: 32px;
          bottom: 0;
          left: 0;
          width: 100%;
          overflow: hidden;
        }

        div:first-child {
          float: left;
          font-size: 18px;
          line-height: 25px;
        }

        #fill_amount {
          float: right;
          font-weight: bold;
          font-size: 24px;
          line-height: 25px;
        }
      }

      &__additional-info {
        color: $black;
        max-width: 480px;
        width: 100%;
        margin: 0 auto;

        &__row {
          div {
            font-size: 18px;
            line-height: 25px;
            display: inline;

            &:first-child {
              padding-right: $baseUnit;
            }
            &:last-child {
              font-weight: 600;
            }
          }
        }
      }
    }

    &__footer {
      position: relative;
      z-index: 1;
      padding: $baseUnit * 14 $baseUnit * 2 0;
      max-width: 611px;
      margin: 0 auto;
      width: 100%;

      &::before {
        content: "";
        height: 1px;
        background: $black;
        width: 100%;
        display: block;
        margin-bottom: $baseUnit * 8;
      }

      &__link {
        font-weight: bold;
        text-transform: uppercase;
        color: $primary;
        text-decoration: none;
        padding: $baseUnit * 2;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  #search-result-close {
    display: block;
    margin: $baseUnit * 8 auto 0;
  }
}
