.owl-prev,
.owl-next {
  width: 49px;
  height: 88px;
  background-color: $white !important;
  color: $primary !important;
  font-size: 0 !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: absolute;
  margin-top: -110px;
}

.owl-prev {
  background-image: url("../assets/arrow-back.svg") !important;
  left: 0;
  display: none;

  @media only screen and (min-width: $screen-md) {
    display: block;
  }
}

.owl-next {
  background-image: url("../assets/arrow-next.svg") !important;
  right: 0;
}
