.order {
  .wrapper {
    margin-top: $baseUnit * 20;
    margin-bottom: $baseUnit * 20;
  }

  &__heading {
    font-weight: normal;
    font-size: 49.0119px;
    line-height: 57px;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: $baseUnit * 12;
  }

  .feedback {
    flex-wrap: wrap;
    padding: $baseUnit * 4;

    @media (min-width: $screen-md) {
      padding: $baseUnit * 5 $baseUnit * 12 $baseUnit * 6;
      flex-wrap: nowrap;
    }

    &__content {
      font-size: 18px;
      line-height: 23px;
      color: $black;
      font-weight: normal;
    }
  }
}
