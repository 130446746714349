.moreInfo {
  background: $gray;
  padding: $baseUnit * 7 0;

  @media only screen and (min-width: $screen-md) {
    padding: $baseUnit * 19 0;
  }

  &__heading {
    margin-bottom: $baseUnit * 6;

    @media only screen and (min-width: $screen-md) {
      margin-bottom: $baseUnit * 12;
      text-align: center;
    }
  }

  &__content {
    @media only screen and (min-width: $screen-md) {
      display: flex;
    }

    &__left {
      flex: 1;
      margin-bottom: $baseUnit * 4;

      @media only screen and (min-width: $screen-md) {
        padding: 0 $baseUnit * 3;
        display: flex;
        justify-content: flex-end;
        text-align: right;
        margin-bottom: $baseUnit * 0;
      }

      p {
        font-size: 18px;
        line-height: 27px;
        max-width: 305px;
      }
    }

    &__right {
      @media only screen and (min-width: $screen-md) {
        padding: 0 $baseUnit * 3;
        flex: 1;
      }
    }

    &__form {
      @media only screen and (min-width: $screen-md) {
        max-width: 295px;
      }
    }
  }
}
