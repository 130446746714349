.references {
  background-color: $bg;
  padding: $baseUnit * 11 0 $baseUnit * 18;

  &__heading {
    text-align: left;
    display: block;
    margin-bottom: $baseUnit * 7;

    @media only screen and (min-width: $screen-md) {
      text-align: center;
      margin-bottom: $baseUnit * 12;
    }
  }

  &__carousel {
    position: static !important;

    &__item {
      font-size: 12px;
      line-height: 18px;
      max-width: 245px;

      @media only screen and (min-width: $screen-md) {
        max-width: 275px;
        font-size: 18px;
        line-height: 27px;
        padding-right: $baseUnit * 6;
      }

      &__author {
        color: $primary;
      }

      &__profession {
        font-weight: bold;
        color: $black;

        &::before {
          margin-right: $baseUnit;
          content: "/";
        }
      }
    }
  }
}
