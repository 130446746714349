*::before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: $black;
}

main {
  padding-top: 149px;

  @media only screen and (min-width: $screen-md) {
    padding-top: 125px;
  }
}

.wrapper {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  padding: 0 $baseUnit * 4;

  @media only screen and (min-width: $screen-md) {
    padding: 0 $baseUnit * 10;
  }

  &-relative {
    position: relative;
  }

  &--small {
    max-width: 807px;
  }
}

a {
  color: $darkGray;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $primary;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  text-transform: uppercase;
}

h2 {
  color: $primary;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 21px;

  @media only screen and (min-width: $screen-md) {
    font-size: 27px;
    line-height: 32px;
  }
}

p {
  margin-top: 0;
  font-size: 14px;
  line-height: 20px;

  @media only screen and (min-width: $screen-md) {
    font-size: 18px;
    line-height: 27px;
  }

  &.perex {
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;

    @media only screen and (min-width: $screen-md) {
      font-size: 21px;
      line-height: 34px;
    }
  }

  &.quote {
    font-size: 18px;
    line-height: 27px;
    font-style: italic;
    border-left: 6px solid $primary;
    padding-left: $baseUnit * 6;

    @media only screen and (min-width: $screen-md) {
      font-size: 21px;
      line-height: 34px;
    }
  }
}

td {
  font-size: 14px;
  line-height: 20px;

  @media only screen and (min-width: $screen-md) {
    font-size: 18px;
    line-height: 27px;
  }
}

ul {
  li {
    font-size: 14px;
    line-height: 20px;

    @media only screen and (min-width: $screen-md) {
      font-size: 18px;
      line-height: 27px;
    }
  }
}

form {
  .form-section {
    margin-bottom: $baseUnit * 10;

    @media only screen and (min-width: $screen-md) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__heading {
      font-weight: bold;
      font-size: 27px;
      line-height: 32px;
      margin-bottom: $baseUnit * 10;
      color: $black;
      width: 100%;
    }

    .form-group {
      @media only screen and (min-width: $screen-md) {
        width: 50%;
        padding-right: $baseUnit * 8;
      }

      &--full {
        width: 100%;
      }

      &--small {
        @media only screen and (min-width: $screen-md) {
          width: 30%;
          padding-right: $baseUnit * 8;
        }
      }
      &--half {
        @media only screen and (min-width: $screen-md) {
          width: 25%;
          padding-right: $baseUnit * 8;
        }
      }
    }
  }

  .input-with-text {
    display: flex;
    .additional-text {
      display: none;
      padding-top: 8px;
      @media only screen and (min-width: $screen-md) {
        display: block;
      }
    }
  }

  .form-group {
    margin-bottom: $baseUnit * 4;

    .input-group {
      display: flex;
      position: relative;
      padding-bottom: $baseUnit * 4;

      &--half {
        @media only screen and (min-width: $screen-md) {
          width: calc(50% - 20px);
        }
      }

      input {
        flex: 1;
      }

      img {
        padding: 0 10px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          opacity: 0.5;
        }
      }

      a {
        font-size: 14px;
        padding-left: 10px;
        line-height: 36px;
      }

      &--error {
        input,
        select,
        textarea {
          border: 1px solid $primary;
        }
      }

      input,
      select,
      textarea {
        &.error {
          border: 1px solid $primary;
        }
      }

      .error:not(input) {
        font-size: 14px;
        line-height: 20px;
        color: $primary;
        position: absolute;
        bottom: -6px;
        text-transform: none;
      }
    }

    label {
      text-transform: uppercase;
      color: $textGray;
      display: block;
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      display: flex;
      align-items: center;
      margin-bottom: $baseUnit;

      span {
        padding: 0 $baseUnit;
      }
    }

    input,
    textarea,
    select {
      background-color: $bg;
      border: none;
      display: block;
      font-size: 14px;
      width: 100% !important;
      border: 1px solid transparent;

      &.half {
        width: 50% !important;
      }

      &:disabled,
      &.disabled {
        background-color: $disabled;
      }
    }

    select {
      -webkit-appearance: none;
      -webkit-border-radius: 0px;
      position: relative;
      background-image: url("../assets/dropdown.svg");
      background-repeat: no-repeat;
      background-position: right;
      background-size: contain;
    }

    input,
    select {
      height: 36px;
      padding: 0 $baseUnit * 2;
    }

    textarea {
      padding: $baseUnit * 2;
      height: 112px !important;
      max-height: 112px !important;
      min-height: 112px !important;
    }
  }

  button {
    background-color: $primary;
    color: $white;
    font-weight: bold;
    line-height: 1.5;
    text-transform: uppercase;
    border: 1px solid transparent;
    text-decoration: none;
    font-size: 12px;
    padding: $baseUnit $baseUnit * 4;
    transition: all 0.3s;
    cursor: pointer;

    @media only screen and (min-width: $screen-md) {
      font-size: 19px;
      padding: $baseUnit * 2 $baseUnit * 7;
    }

    &:hover,
    &:focus {
      border: 1px solid $primary;
      background-color: $white;
      color: $primary;
    }
  }
}

ol {
  list-style: none;
  counter-reset: list;
  padding: 0;
}

ol li {
  counter-increment: list;
  margin-bottom: $baseUnit * 6;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;

  @media only screen and (min-width: $screen-md) {
    font-size: 24px;
    line-height: 36px;
  }

  a {
    padding: 0 4px;
  }
}

ol li::before {
  content: counter(list) "";
  color: $white;
  background-color: $primary;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: $baseUnit * 4;
  font-size: 18px;
  height: 52px;
  min-width: 52px;
  height: 52px;

  @media only screen and (min-width: $screen-md) {
    margin-right: $baseUnit * 10;
    font-size: 24px;
    width: 69px;
    min-width: 69px;
    height: 69px;
  }
}

.hint {
  position: relative;
  cursor: pointer;

  img {
    margin: 0 0px -4px 2px;
    height: 18px;
  }

  &__text {
    top: -26px;
    left: 0;
    font-size: 12px;
    display: none;
    padding: 0 $baseUnit;
    color: $white;
    background-color: $black;
    position: absolute;
  }
}

button,
.button {
  display: inline-block;
  background-color: $primary;
  color: $white;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
  border: 1px solid transparent;
  text-decoration: none;
  font-size: 12px;
  padding: $baseUnit $baseUnit * 4;
  cursor: pointer;
  margin-bottom: $baseUnit * 9;
  transition: all 0.3s;

  @media only screen and (min-width: $screen-md) {
    margin-bottom: $baseUnit * 11;
    font-size: 14px;
    padding: $baseUnit * 2 $baseUnit * 6;
  }

  &:hover,
  &:focus {
    border: 1px solid $primary;
    background-color: $white;
    color: $primary;
  }

  &--invert {
    background-color: $white;
    color: $primary;
    border: 1px solid $primary;

    &:hover,
    &:focus {
      background-color: $primary;
      color: $white;
    }
  }
}

.center {
  display: flex;
  justify-content: center;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: $baseUnit * 7;

  label {
    font-size: 14px;
    line-height: 20px;

    @media only screen and (min-width: $screen-md) {
      font-size: 18px;
      line-height: 27px;
    }
  }
}

input[type="checkbox"] {
  height: 0;
  width: 0;
  display: none;
}

input[type="checkbox"] + label {
  position: relative;
  display: flex;
  margin: 14px 0;
  align-items: center;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
  display: flex;

  a {
    padding: 0 4px;
  }
}

input[type="checkbox"] + label > span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  width: 24px;
  height: 24px;
  background: transparent;
  border: 2px solid #9e9e9e;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
  min-width: 24px;
  min-height: 24px;
  position: relative;
}

input[type="checkbox"] + label:hover > span,
input[type="checkbox"]:focus + label > span {
  background: rgba(255, 255, 255, 0.1);
}

input[type="checkbox"]:checked + label > span {
  border: 12px solid $bg;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}
input[type="checkbox"]:checked + label > span:before {
  content: "";
  position: absolute;
  top: 2px;
  left: -6px;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 6px;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 6px;
    height: 12px;
    border-color: #212121;
    transform: translate3d(0, -12px, 0) rotate(45deg);
  }
}

.divider {
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: $baseUnit * 8;

  &::after {
    content: "";
    width: 100%;
    position: absolute;
    top: 50%;
    border-bottom: 1px solid $border;
  }

  &__content {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 32px;
    color: $border;
    background: $white;
    position: relative;
    z-index: 2;

    img {
      padding: 0 $baseUnit * 4;
    }
  }

  &__link {
    font-size: 14px;
    line-height: 20px;
    padding-right: $baseUnit * 3;
  }
}

.feedback {
  padding: $baseUnit * 9;
  background-color: $successBg;
  margin-bottom: $baseUnit * 10;
  display: flex;

  &--failure {
    background-color: $errorBg;
    color: $primary;
  }

  &__image {
    margin-right: $baseUnit * 8;
    margin-bottom: $baseUnit * 4;
  }

  &__content {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
}

input[type="radio"] {
  display: none !important;
}

input[type="radio"] + label {
  width: 100%;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  margin-bottom: $baseUnit * 2;

  span {
    padding-left: 35px;
    text-transform: none;
    color: $black;
    font-size: 14px;
    line-height: 24px;

    @media only screen and (min-width: $screen-md) {
      font-size: 18px;
      line-height: 30px;
    }
  }
}
input[type="radio"]:checked + label {
  animation-play-state: paused;
}
input[type="radio"] + label:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  background: $primary;
  border-radius: 50%;
  transition: all 0.2s;
  top: 4px;
  left: 4px;
  z-index: 2;
}

input[type="radio"] + label:before {
  transform: scale(0);
}
input[type="radio"] + label:after {
  content: "";
  width: 26px;
  height: 26px;
  background: $bg;
  border: 1px solid $border;
  position: absolute;
  border-radius: 100%;
  left: 0;
  z-index: 1;
  top: 0;
}
input[type="radio"]:checked + label:before {
  transform: scale(1);
  transition: all 0.4s;
}

.form__note {
  font-size: 14px;
  line-height: 20px;
  width: 100%;

  @media only screen and (min-width: $screen-md) {
    font-size: 18px;
    line-height: 27px;
  }
}
