$primary: #c4122f;
$white: #fff;
$black: #000;
$gray: #ececec;
$darkGray: #3c3c3c;
$bg: #fbf2f4;
$textGray: #a6a6a6;
$border: #737373;
$disabled: #f3f3f3;
$success: #8fc791;
$successBg: rgba(143, 199, 145, 0.1);
$errorBg: rgba(196, 18, 47, 0.1);

$baseUnit: 5px;

$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1220px;
