.discount {
  .wrapper {
    margin-top: $baseUnit * 20;
    margin-bottom: $baseUnit * 20;
  }

  &__heading {
    font-weight: normal;
    font-size: 49.0119px;
    line-height: 57px;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: $baseUnit * 12;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $screen-md) {
      flex-wrap: nowrap;
    }

    img {
      margin-top: 8px;
      margin-right: $baseUnit * 5;
      height: 90px;
      width: auto;
    }
  }

  &__sub-heading {
    font-weight: bold;
    font-size: 25.1266px;
    line-height: 36px;
    margin-bottom: $baseUnit * 6;
  }

  .form-section__heading {
    color: $primary;
  }

  .form-group {
    @media (min-width: $screen-md) {
      width: 50%;
      margin-right: 50%;
    }
  }

  input[type="submit"] {
    margin-bottom: 0;

    &[disabled="disabled"] {
      opacity: 0.5;
    }
  }

  .feedback {
    flex-wrap: wrap;
    padding: $baseUnit * 4;

    @media (min-width: $screen-md) {
      padding: $baseUnit * 5 $baseUnit * 12 $baseUnit * 6;
      flex-wrap: nowrap;
    }

    &__content {
      font-size: 18px;
      line-height: 23px;
      color: $black;
      font-weight: normal;
    }
  }
}
