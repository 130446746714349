.products {
  @media only screen and (min-width: $screen-md) {
    background-color: $gray;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media only screen and (max-width: $screen-md - 0.02) {
      padding: 0;
    }
  }

  &__card {
    width: 570px;
    max-width: 48%;
    position: relative;

    @media only screen and (min-width: $screen-md) {
      position: relative;
      z-index: 25;
      top: $baseUnit * -11;
    }

    &__note {
      position: absolute;
      top: 100%;
      font-size: 12px;
      line-height: 24px;
      width: 100vw;

      @media (min-width: $screen-md) {
        width: initial;
        right: 0;
        text-align: right;
      }
    }

    &__content {
      background-color: $primary;
      display: flex;
      flex-wrap: wrap;
      padding: $baseUnit * 4 $baseUnit * 3 $baseUnit * 4 $baseUnit * 3;

      @media only screen and (min-width: $screen-md) {
        padding: $baseUnit * 5 $baseUnit * 4 $baseUnit * 3 $baseUnit * 4;
      }

      @media only screen and (min-width: $screen-lg) {
        padding: $baseUnit * 7 $baseUnit * 6 $baseUnit * 3 $baseUnit * 6;
      }

      @media only screen and (min-width: $screen-xl) {
        padding: $baseUnit * 10 $baseUnit * 8 $baseUnit * 6 $baseUnit * 8;
      }

      &__left {
        width: 100%;
        text-align: center;
        margin-bottom: $baseUnit * 3;

        @media only screen and (min-width: $screen-md) {
          width: 60px;
          text-align: left;
          margin-bottom: 0;
        }

        @media only screen and (min-width: $screen-lg) {
          width: 100px;
        }

        @media only screen and (min-width: $screen-xl) {
          width: 130px;
        }
      }

      &__right {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        text-align: center;

        @media only screen and (min-width: $screen-md) {
          text-align: left;
          align-items: flex-start;
          flex: 1;
          margin-left: $baseUnit * 5;
        }

        @media only screen and (min-width: $screen-lg) {
          margin-left: $baseUnit * 4;
        }

        @media only screen and (min-width: $screen-xl) {
          margin-left: $baseUnit * 8;
        }
      }

      &__image {
        max-width: 74px;
        width: 100%;
        max-height: 71px;
        height: 71px;

        @media only screen and (min-width: $screen-md) {
          max-width: 100%;
          max-height: 123px;
          height: auto;
          width: 100%;
        }
      }

      &__heading {
        font-weight: 600;
        padding: 0;
        margin: 0 0 $baseUnit * 2;
        color: $white;
        font-size: 14px;
        line-height: 18px;
        min-height: 36px;

        @media only screen and (min-width: $screen-md) {
          min-height: 0;
          line-height: 24px;
          font-size: 16px;
        }

        @media only screen and (min-width: $screen-lg) {
          line-height: 24px;
          font-size: 20px;
        }

        @media only screen and (min-width: $screen-xl) {
          font-size: 28px;
        }

        &__light {
          display: block;
          font-weight: 100;
          font-size: 10px;
          padding-top: 5px;

          @media only screen and (min-width: $screen-lg) {
            font-size: 16px;
          }
        }
      }

      &__sub-heading {
        line-height: 24px;
        font-size: 16px;
        color: $white;
        margin-bottom: $baseUnit * 5;

        @media only screen and (min-width: $screen-md) {
          margin-bottom: $baseUnit * 9;
          min-height: 0;
          font-size: 21px;
          line-height: 27px;
        }
      }

      &__description {
        margin-bottom: $baseUnit * 3;

        &__wrapper {
          @media only screen and (min-width: $screen-md) {
            display: flex;
            align-items: center;
          }
        }

        &__price {
          padding-top: $baseUnit * 2;
          height: 81px;
          width: 81px;
          min-width: 81px;
          border-radius: 81px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $white;
          background-color: $darkGray;
          font-size: 20px;
          line-height: 0.9;
          flex-flow: column;
          margin: 0 auto $baseUnit * 3;

          @media only screen and (min-width: $screen-md) {
            margin: 0;
          }

          &__vat {
            font-size: 10px;
            line-height: 1.8;
          }
        }

        &__info {
          min-height: 31px;
          color: $white;

          font-size: 10px;
          line-height: 14px;

          @media only screen and (min-width: $screen-md) {
            margin-left: $baseUnit * 2;
            min-height: 54px;
            line-height: 27px;
            font-size: 16px;
          }

          &--light {
            font-weight: 100;
          }
        }
      }

      &__button {
        display: inline-block;
        background-color: $white;
        color: $primary;
        font-weight: bold;
        line-height: 1.5;
        text-transform: uppercase;
        border: 1px solid transparent;
        text-decoration: none;
        font-size: 12px;
        padding: $baseUnit $baseUnit * 4;
        margin-top: auto;

        @media only screen and (min-width: $screen-md) {
          font-size: 19px;
          padding: $baseUnit * 2 $baseUnit * 7;
        }

        &:hover,
        &:focus {
          border: 1px solid $white;
          background-color: $primary;
          color: $white;
        }
      }
    }
  }

  &__footer {
    width: 100%;
    background-color: $gray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 $baseUnit * 6.5;
    text-align: center;
    flex-flow: column;
    padding-top: 15px;

    @media only screen and (min-width: $screen-md) {
      background-color: $white;
      position: relative;
      z-index: 5;
      top: $baseUnit * -11;
    }

    &__link {
      font-size: 12px;
      height: 83px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      @media only screen and (min-width: $screen-md) {
        font-size: 16px;
      }

      @media only screen and (min-width: $screen-lg) {
        font-size: 18px;
      }
    }

    &__content {
      display: none;

      &__heading {
        font-size: 18px;
        line-height: 21px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: $baseUnit * 8;

        @media only screen and (min-width: $screen-md) {
          margin-bottom: $baseUnit * 6;
          font-size: 27.3799px;
          line-height: 32px;
        }
      }

      &__button {
        background-color: $primary;
        color: $white;
        font-weight: bold;
        line-height: 1.5;
        text-transform: uppercase;
        border: 1px solid transparent;
        text-decoration: none;
        font-size: 12px;
        padding: $baseUnit $baseUnit * 4;
        transition: all 0.3s;
        cursor: pointer;
        display: table;
        margin: $baseUnit * 6 auto $baseUnit * 8;

        @media only screen and (min-width: $screen-md) {
          font-size: 19px;
          padding: $baseUnit * 2 $baseUnit * 7;
          margin: $baseUnit * 12 auto $baseUnit * 16;
        }

        &:hover,
        &:focus {
          border: 1px solid $primary;
          background-color: $white;
          color: $primary;
        }
      }
    }
  }

  &__list {
    li {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: left;

      @media only screen and (min-width: $screen-md) {
        font-size: 18px;
        line-height: 27px;
      }

      &::before {
        min-width: 34.1px;
        width: 34.1px;
        height: 34.1px;
        font-size: 11.6059px;
        line-height: 13px;
      }
    }
  }
}
