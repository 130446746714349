.audience {
  display: flex;
  padding: $baseUnit * 10 $baseUnit * 3;

  @media only screen and (min-width: $screen-md) {
    padding: $baseUnit * 22 $baseUnit * 8;
    background-color: $gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__half {
    width: 100%;

    @media only screen and (min-width: $screen-md) {
      width: 48%;
    }

    &--left {
      text-align: right;
      display: none;

      @media only screen and (min-width: $screen-md) {
        display: block;
      }
    }
  }

  &__image {
    max-width: 100%;
    height: auto;
  }

  &__heading {
    margin-bottom: $baseUnit * 4;
    max-width: 333px;
  }

  &__paragraph {
    max-width: 460px;
    margin-bottom: $baseUnit * 4;
    font-size: 14px;
    line-height: 20px;

    @media only screen and (min-width: $screen-md) {
      font-size: 18px;
      line-height: 27px;
    }
  }
}
