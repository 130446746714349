.recap {
  .wrapper {
    margin-top: $baseUnit * 20;
    margin-bottom: $baseUnit * 20;
  }

  &__heading {
    font-size: 49.0119px;
    line-height: 57px;
    font-weight: bold;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: $baseUnit * 5;
  }

  &__section {
    margin-bottom: $baseUnit * 15;
    &__heading {
      font-weight: bold;
      font-size: 27px;
      line-height: 32px;
      margin-bottom: $baseUnit * 10;
      color: $black;
      width: 100%;
    }

    &__item {
      margin-bottom: $baseUnit * 3;

      &--text {
        img {
          padding-left: $baseUnit * 3;
        }
      }
      &__label {
        text-transform: uppercase;
        color: $textGray;
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        margin-right: $baseUnit * 3;
        display: block;
        @media only screen and (min-width: $screen-md) {
          display: inline-block;
        }
      }

      &__content {
        font-size: 14px;
        line-height: 20px;
        font-size: 14px;
        line-height: 20px;

        @media only screen and (min-width: $screen-md) {
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }
}
