.footer {
  background-color: $darkGray;
  padding-top: $baseUnit * 8;
  padding-bottom: $baseUnit * 24;
  color: $white;

  p {
    font-size: 14px;
    line-height: 20px;

    @media only screen and (min-width: $screen-md) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &__logo {
    text-align: center;
    margin-bottom: $baseUnit * 16;
    img {
      width: 114px;
    }
  }

  &__half {
    @media only screen and (min-width: $screen-md) {
      width: 50%;
      float: left;
      padding: 0 $baseUnit * 3;
      margin-bottom: $baseUnit * 9;
    }

    &--left {
      text-align: center;
      margin-bottom: $baseUnit * 8;
      @media only screen and (min-width: $screen-md) {
        margin-bottom: $baseUnit * 0;
        text-align: right;
      }
    }

    &--right {
      text-align: center;
      @media only screen and (min-width: $screen-md) {
        text-align: left;
      }
    }
  }

  &__conditions {
    margin-bottom: $baseUnit * 6;
    clear: both;
    text-align: center;

    &__link {
      color: $white;
    }
  }

  &__socials {
    clear: both;
    text-align: center;
  }
}
