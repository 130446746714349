.certificate {
  padding: $baseUnit * 10 0;

  @media only screen and (min-width: $screen-md) {
    padding: $baseUnit * 19 0;
  }

  &__content {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 $baseUnit * 8;

    &__heading {
      font-weight: bold;
      font-size: 26px;
      line-height: 1.2;
      margin-bottom: $baseUnit * 5;

      @media only screen and (min-width: $screen-md) {
        margin-bottom: $baseUnit * 10;
        font-size: 42px;
        align-items: center;
        display: flex;
      }

      img {
        width: 74px;
        height: auto;
        margin-right: $baseUnit * 7;
        margin-bottom: $baseUnit * 3;

        @media only screen and (min-width: $screen-md) {
          width: 128px;
          margin-bottom: $baseUnit * 5;
          margin-bottom: 0;
        }
      }

      &__text {
        display: block;
        @media only screen and (min-width: $screen-md) {
          flex: 1;
        }
      }
    }

    &__subheading {
      font-weight: bold;
      line-height: 32px;
      color: $primary;
      margin-bottom: $baseUnit * 9;
      font-size: 21px;

      @media only screen and (min-width: $screen-md) {
        font-size: 27px;
      }
    }

    &__note {
      margin-bottom: $baseUnit * 12;
    }
  }
  &__card {
    max-width: 720px;
    margin: 0 auto;

    &__content {
      padding: $baseUnit * 11;
      background-color: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;

      &__price {
        padding-top: $baseUnit * 2;
        height: 81px;
        width: 81px;
        border-radius: 81px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background-color: $darkGray;
        font-size: 20px;
        line-height: 0.9;
        flex-flow: column;
        margin-bottom: $baseUnit * 5;

        &__vat {
          font-size: 10px;
          line-height: 1.8;
        }
      }

      &__button {
        display: inline-block;
        background-color: $white;
        color: $primary;
        font-weight: bold;
        line-height: 1.5;
        text-transform: uppercase;
        border: 1px solid transparent;
        text-decoration: none;
        font-size: 12px;
        padding: $baseUnit $baseUnit * 4;
        cursor: pointer;
        margin-bottom: $baseUnit * 9;

        @media only screen and (min-width: $screen-md) {
          margin-bottom: $baseUnit * 11;
          font-size: 19px;
          padding: $baseUnit * 2 $baseUnit * 7;
        }

        &:hover,
        &:focus {
          border: 1px solid $white;
          background-color: $primary;
          color: $white;
        }
      }
    }

    &__footer {
      width: 100%;
      background-color: $gray;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 $baseUnit * 6.5;
      text-align: center;
      flex-flow: column;

      @media only screen and (min-width: $screen-md) {
        background-color: $bg;
        position: relative;
        z-index: 5;
        top: $baseUnit * -11;
      }

      &__link {
        font-size: 12px;
        height: 83px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }

        @media only screen and (min-width: $screen-md) {
          font-size: 16px;
        }

        @media only screen and (min-width: $screen-lg) {
          font-size: 18px;
        }
      }

      &__content {
        display: none;

        p {
          margin-bottom: $baseUnit * 7;
          text-align: left;
        }

        &__heading {
          font-size: 18px;
          line-height: 21px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: $baseUnit * 8;

          @media only screen and (min-width: $screen-md) {
            margin-bottom: $baseUnit * 6;
            font-size: 27.3799px;
            line-height: 32px;
          }
        }

        &__button {
          background-color: $primary;
          color: $white;
          font-weight: bold;
          line-height: 1.5;
          text-transform: uppercase;
          border: 1px solid transparent;
          text-decoration: none;
          font-size: 12px;
          padding: $baseUnit $baseUnit * 4;
          transition: all 0.3s;
          cursor: pointer;
          display: table;
          margin: $baseUnit * 6 auto $baseUnit * 8;

          @media only screen and (min-width: $screen-md) {
            font-size: 19px;
            padding: $baseUnit * 2 $baseUnit * 7;
            margin: $baseUnit * 12 auto $baseUnit * 16;
          }

          &:hover,
          &:focus {
            border: 1px solid $primary;
            background-color: $white;
            color: $primary;
          }
        }
      }
    }

    &__list {
      li {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: left;

        @media only screen and (min-width: $screen-md) {
          font-size: 18px;
          line-height: 27px;
        }

        &::before {
          min-width: 34.1px;
          width: 34.1px;
          height: 34.1px;
          font-size: 11.6059px;
          line-height: 13px;
        }
      }
    }
  }
}
